var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "800px" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { elevation: "0" } },
            [
              _c("v-card-title", { staticClass: "justify-center" }, [
                _c("span", { staticClass: "subtitle-2" }, [
                  _vm._v(_vm._s(_vm.$t("onboard.welcome")))
                ])
              ]),
              _c(
                "v-card-text",
                { staticClass: "rounded-0" },
                [
                  _c(
                    "v-container",
                    { staticClass: "stepper-template pa-0" },
                    [
                      [
                        _c(
                          "v-stepper",
                          {
                            staticClass: "rounded-0",
                            attrs: { elevation: "0" },
                            model: {
                              value: _vm.step,
                              callback: function($$v) {
                                _vm.step = $$v
                              },
                              expression: "step"
                            }
                          },
                          [
                            _c(
                              "v-stepper-header",
                              [
                                _c(
                                  "v-stepper-step",
                                  {
                                    attrs: {
                                      complete: _vm.step > 1,
                                      step: "1",
                                      editable: _vm.isFormValid
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("onboard.personal")) +
                                        " "
                                    )
                                  ]
                                ),
                                _c("v-divider"),
                                _c(
                                  "v-stepper-step",
                                  {
                                    attrs: {
                                      complete: _vm.step > 2,
                                      step: "2",
                                      editable: _vm.step > 2
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("onboard.start")) +
                                        " "
                                    )
                                  ]
                                ),
                                _c("v-divider"),
                                _c(
                                  "v-stepper-step",
                                  {
                                    attrs: {
                                      complete: _vm.step > 3,
                                      step: "3",
                                      editable: _vm.step > 3
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("onboard.integration")) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-stepper-items",
                              [
                                _c(
                                  "v-stepper-content",
                                  { attrs: { step: "1" } },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass: "mb-4",
                                        attrs: { elevation: "0" }
                                      },
                                      [
                                        _c(
                                          "v-form",
                                          {
                                            ref: "form",
                                            model: {
                                              value: _vm.isFormValid,
                                              callback: function($$v) {
                                                _vm.isFormValid = $$v
                                              },
                                              expression: "isFormValid"
                                            }
                                          },
                                          [
                                            _c("v-text-field", {
                                              staticClass: "mt-3",
                                              attrs: {
                                                label: "Name",
                                                placeholder: "Enter your Name",
                                                rules: _vm.nameRules,
                                                required: "",
                                                outlined: "",
                                                dense: ""
                                              },
                                              model: {
                                                value: _vm.onBoardDetails.name,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.onBoardDetails,
                                                    "name",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "onBoardDetails.name"
                                              }
                                            }),
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "Surname",
                                                placeholder:
                                                  "Enter your Surname",
                                                rules: _vm.surnameRules,
                                                required: "",
                                                outlined: "",
                                                dense: ""
                                              },
                                              model: {
                                                value:
                                                  _vm.onBoardDetails.surname,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.onBoardDetails,
                                                    "surname",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "onBoardDetails.surname"
                                              }
                                            }),
                                            _c("v-text-field", {
                                              directives: [
                                                {
                                                  name: "mask",
                                                  rawName: "v-mask",
                                                  value: "(###) ###-####",
                                                  expression: "'(###) ###-####'"
                                                }
                                              ],
                                              attrs: {
                                                label: "Phone",
                                                placeholder:
                                                  "Enter your phone number",
                                                rules: _vm.phoneRules,
                                                required: "",
                                                outlined: "",
                                                dense: ""
                                              },
                                              model: {
                                                value: _vm.onBoardDetails.phone,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.onBoardDetails,
                                                    "phone",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "onBoardDetails.phone"
                                              }
                                            }),
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "Company Name",
                                                placeholder:
                                                  "Enter your Company Name",
                                                rules: _vm.companyRules,
                                                required: "",
                                                outlined: "",
                                                dense: ""
                                              },
                                              model: {
                                                value:
                                                  _vm.onBoardDetails.company,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.onBoardDetails,
                                                    "company",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "onBoardDetails.company"
                                              }
                                            }),
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "Job Title",
                                                placeholder: "Enter job title",
                                                rules: _vm.jobRules,
                                                required: "",
                                                outlined: "",
                                                dense: ""
                                              },
                                              model: {
                                                value: _vm.onBoardDetails.job,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.onBoardDetails,
                                                    "job",
                                                    $$v
                                                  )
                                                },
                                                expression: "onBoardDetails.job"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "text-center my-2" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              width: "164",
                                              color: "success",
                                              depressed: "",
                                              large: "",
                                              loading:
                                                _vm.savingPersonalDetails,
                                              disabled: !_vm.isFormValid
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.save()
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("onboard.next")) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-stepper-content",
                                  { attrs: { step: "2" } },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass: "mb-12",
                                        attrs: { elevation: "0" }
                                      },
                                      [
                                        _c(
                                          "v-list",
                                          { attrs: { "three-line": "" } },
                                          [
                                            _vm._l(_vm.items, function(item) {
                                              return [
                                                _c(
                                                  "v-list-item",
                                                  { key: item.title },
                                                  [
                                                    _c(
                                                      "v-list-item-avatar",
                                                      [
                                                        _c("v-img", {
                                                          attrs: {
                                                            src: item.avatar
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          {
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                item.title
                                                              )
                                                            }
                                                          }
                                                        ),
                                                        _c(
                                                          "v-list-item-subtitle",
                                                          {
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                item.subtitle
                                                              )
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            })
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "text-center my-2" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              width: "164",
                                              color: "success",
                                              depressed: "",
                                              large: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.step = 3
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("onboard.trial")
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-stepper-content",
                                  { attrs: { step: "3" } },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass: "mb-12",
                                        attrs: { elevation: "0" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-subtitle-1 font-weight-medium"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("onboard.addInt")
                                                ) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c("div", { staticClass: "body-2" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("onboard.signInApple")
                                              )
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("onboard.step1"))
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("onboard.step2")) +
                                              " "
                                          )
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "upload-file text-center"
                                          },
                                          [_c("SigninAppleBtn")],
                                          1
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "text-center my-2" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              width: "164",
                                              color: "success",
                                              depressed: "",
                                              large: "",
                                              to: "/overview"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("onboard.skip")) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }