














































































































































































  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-nocheck
import Vue from 'vue';
import { OnboardService } from '@services/onboard.service';
import { CB_SubOnboard } from '../utils/apptweakqueries';
import { LSS } from '../core/services';
import SigninAppleBtn from '../components/oauth/SigninAppleBtn.vue';
import { LoginService } from '@services/login.service';
import { decodeToken } from '@shared/utils/commom.util'
import VueMask from 'v-mask'

Vue.use(VueMask)
export default Vue.extend({
  name: 'Onboard',
  data() {
    return {
      savingPersonalDetails: false,
      saving: false,
      onBoardDetails: {
        name: '',
        surname: '',
        phone: '',
        company: '',
        job: ''
      },
      dialog: true,
      step: 1,
      items: [
        {
          avatar: '../assets/images/apple.svg',
          title: 'Apple Search Ads Funnel Track',
          subtitle:
            'Track your entire user acquisition funnel with crucial metrics such as ROAS, ARPU, CPI, and revenue. See exactly how much you spend and how much you get in return on a single dashboard.',
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
          title: 'Custom Goal Tracking',
          subtitle: `Determine your own goals based on your KPIs and track them to see your real performance. Choose any in-app event as your goal and analyze the effect of your campaign efforts on your custom goals.`,
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
          title: 'Organic Keyword Hunt',
          subtitle:
            'Uncover the organic keywords that your competitors are ranking for. Analyze your competitors’ rankings, popularity, and difficulty of each keyword to immediately run ads on them.',
        },
      ],
      isFormValid: false,
      nameRules: [
        v => !!v && !!v.trim() || 'First name is required!',
      ],
      surnameRules: [
        v => !!v && !!v.trim() || 'Surname is required!',
      ],
      phoneRules: [
        v => !!v && !!v.trim() || 'Phone is required!',
      ],
      companyRules: [
        v => !!v && !!v.trim() || 'Company is required!',
      ],
      jobRules: [
        v => !!v && !!v.trim() || 'Job title is required!',
      ]
    };
  },
  components: {
    SigninAppleBtn
  },
  computed: {
    c: function (): string {
      return '';
    },
  },
  methods: {
    async save() {
      this.savingPersonalDetails = true;
      await OnboardService.save(decodeToken(LSS.token).UserId, {
        FirstName: this.onBoardDetails.name,
        LastName: this.onBoardDetails.surname,
        Phone: this.onBoardDetails.phone,
        CompanyName: this.onBoardDetails.company,
        JobTitle: this.onBoardDetails.job,
      })
        .then(async (res) => {
          this.step = 2;
          this.savingPersonalDetails = false;
          const user = await this.getUserDetails(decodeToken(LSS.token).UserId);
          this.createSubPlan();
        })
        .catch((err) => {
          this.savingPersonalDetails = false;
          this.saving = false;
          this.$notify.error('Unable to save details. Please try again!');
        });
    },
    createSubPlan() {
      this.$apollo
      .query({
        query: CB_SubOnboard,
        variables: {
            plan_id : 'startup-incremental-pricing',
            invoice_immediately : false,
            first_name : this.onBoardDetails.name,
            last_name : this.onBoardDetails.surname,
            customer_id : decodeToken(LSS.token).UserId,
            email : decodeToken(LSS.token).Email
        }
      })
      .then(data => {
        //console.log(data.data);
      })
      .catch(error => {
        //console.log(error);
      });
    },
    async getUserDetails(UserID: string) {
      const token = await LoginService.getUserDetails({
        UserID: UserID,
        SessId: null
      });
      const user = decodeToken(token);
      //console.log(user);
      LSS.token = token
      this.$root.$emit('userNameFetched', user);
      return user;
    },
  },
});
