import { IOnboardDetails, ISuccess } from '@shared/interfaces';
import { joinUrl, replaceParamsWithValues } from '@shared/utils';

import { API_BASE_URL } from '@config';
import { HttpService } from '@core/services';
import { ONBOARD_ROUTES } from '@shared/config';

/**
 * @class OnboardService
 * @description service class for handling onboarding process.
 */
export class OnboardService {
  private static get url() {
    return joinUrl(API_BASE_URL as string, ONBOARD_ROUTES.NoVbase);
  }

  /**
   * @function
   * @name save
   * @description service function for saving onboarding details
   * @param {string} securityPrincipleID
   * @param {any} onboardDetails
   * @returns {Promise<any>} response as a promise
   */
  public static async save(securityPrincipleID: string, onboardDetails: IOnboardDetails): Promise<ISuccess> {
    const response = await HttpService.post<ISuccess>(joinUrl(this.url, replaceParamsWithValues(ONBOARD_ROUTES.save, securityPrincipleID) ), onboardDetails);
    return response;
  }
}
